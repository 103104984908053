const caseInsensitiveSort = ({ row1, row2, columnName }) => {
    const rowOneColumn = row1.values[columnName];
    const rowTwoColumn = row2.values[columnName];

    if (rowOneColumn === null || rowOneColumn === undefined) {
        return -1;
    }

    if (rowTwoColumn === null || rowTwoColumn === undefined) {
        return 1;
    }

    if (isNaN(rowOneColumn)) {
        return rowOneColumn.toUpperCase() >
            rowTwoColumn.toUpperCase()
            ? 1
            : -1;
    }
    return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
};

export { caseInsensitiveSort };